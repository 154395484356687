import React from 'react';
import { State } from '@hookstate/core';
import { Button, Collapse, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import FilterAutocompleteType from '~/components/FilterAutocompleteType';
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypesByType';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import FilterAutocompleteDispatchGroup from '~/components/FilterAutocompleteDispatchGroup';
import FilterAutocompleteArea from '~/components/FilterAutocompleteArea';

interface ManagementFiltersProps {
  readonly deviceId: State<number | null>;
  readonly areaId: State<number | null>;
  readonly groupId: State<number | null>;
  readonly agencyId: State<number | null>;
  readonly typeId: State<number | null>;
  readonly subtypeId?: State<number | null>;
  readonly hidden?: boolean;
  readonly lockFields: boolean;
  readonly searchButton: () => void;
  readonly clearFilters: () => void;
}


export default function AreaConfigFilters({ areaId, lockFields, deviceId, groupId, agencyId, typeId, subtypeId, hidden = true, clearFilters, searchButton }: ManagementFiltersProps) {
  const { translate } = useLang();

  return (
    <Collapse in={hidden}>
      <Grid container xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          pb: 2
        }}
      >
        <Grid container direction={'row'}>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteType
              disabled={lockFields}
              typeId={typeId.get()}
              onTypeChange={(type) => typeId.set(Number(type?.id) ?? null)} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteSubtypesByType
              disabled={lockFields}
              hasType
              typeId={typeId.get()}
              onSubtypeChange={(subtype) => subtypeId?.set(subtype?.id ?? null)} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteAgency
              disabled={lockFields}
              agencyId={agencyId.get()}
              onAgencyChange={(agency) => agencyId.set(agency)} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteArea
              onAreaChange={(area) => areaId.set(area)}
              areaId={areaId.get()}
              disabled={lockFields}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchGroup
              disabled={lockFields}
              agencyId={agencyId.get() ?? undefined}
              dispatchGroupId={groupId.get() ?? undefined}
              onGroupDispatchChange={(group) => {
                groupId.set(group)
                deviceId.set(null)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pt: 1
          }}>
            <Button
              sx={{ mr: 2 }}
              variant="outlined"
              onClick={clearFilters}
            >
              {translate('Clear Filters')}
            </Button>
            <Button
              variant="contained"
              onClick={searchButton}
            >
              {translate('Search')}
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </Collapse>

  )
}