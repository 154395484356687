import React from 'react'
import { AppRouteProps } from '~/routes'
import Index from './pages/Index'
import List from './pages/List'
import Dashboard from './pages/Dashboard'
import ManagementStations from './pages/ManagementStations'

export default function EntryRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {
      path: '/entry',
      component: Index,
      isProtected: true,
      permissions: ['entry_index'],
    },
    {
      path: '/entry/list',
      component: List,
      isProtected: true,
      permissions: ['entry_index'],
    },
    {
      path: '/entry/dashboard',
      component: Dashboard,
      isProtected: true,
      permissions: ['entry_dashboard'],
    },
    {
      path: '/entry/management/stations',
      component: ManagementStations,
      isProtected: true,
      permissions: ['entry_overview'],
    },
  ]

  return routes
}
