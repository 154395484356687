/* eslint-disable max-lines-per-function */
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import React, { useEffect } from 'react'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import InternalHeader from '~/components/Layout/components/InternalHeader'
import InternalContent from '~/components/Layout/components/InternalContent'
import StationCardMonitor from '~/features/Entry/components/StationCardMonitor'
import {
  RGL,
  Responsive,
  WidthProvider as widthProvider,
} from 'react-grid-layout'
import {
  deleteTag,
  getManagentStationsEntryService,
  getManagentStationsTagService,
  saveStationsManagementTags,
  saveStationsPositions,
} from '~/features/Entry/services'
import { Downgraded, useHookstate, useState } from '@hookstate/core'
import {
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Chip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import SaveIcon from '@mui/icons-material/Save'
import useStationState from '~/features/Station/stores/StationState'
import notify from '~/utils/notify'
import ConfirmDialog from '~/components/ConfirmDialog'
import Delete from '@mui/icons-material/Delete'

const ResponsiveGridLayout = widthProvider(Responsive)

interface IDivisors {
  readonly id?: number,
  readonly name: string
  readonly position_x: number
  readonly position_y: number
  readonly width: number
  readonly height: number
}

interface ILayout {
  readonly i: string
  readonly h: number
  readonly w: number
  readonly x: number
  readonly y: number
}
export interface IStation {
  readonly station: {
    readonly id: number
    readonly name: string
    readonly branch_line: string
    readonly position_x: number
    readonly position_y: number
    readonly width: number
    readonly height: number
  }
  readonly station_is_online: boolean
  readonly user: {
    readonly id: number
    readonly representation_name: string
  }
  readonly open_entry: boolean
  readonly last_closed_entry: {
    readonly entry_status: string
    readonly created_at: string
    readonly ended_at: string
    readonly occurrence_id: number
  }
}

export default function ManagementStations() {
  const { translate } = useLang()
  const stations = useHookstate<IStation[]>([])
  const divisors = useHookstate<IDivisors[]>([])
  const divisorsListOpen = useHookstate(false)
  const newDivisors = useState('')
  const { station } = useStationState()
  const forceRefresh = useHookstate(false)
  const openDialog = useHookstate(false)
  const loading = useHookstate(false)
  const removedTag = useHookstate<IDivisors>({name: '', position_x: 0, position_y: 0, width: 0, height: 0})
  const tagIndex = useHookstate<number | null>(null)

  useEffect(() => {
    getManagentStationsEntryService().then((data) => {
      stations.set(data)
    })
    getManagentStationsTagService().then((data) => {
      divisors.set(data)
    })
  }, [forceRefresh.get()])

  const handleChangeLayout = (newLayout: ILayout[]) => {
    newLayout.map((newItem) => {
      const type = newItem.i.split('-')
      if (type[0] == 'station') {
        stations[type[1]].station.position_x.set(Number(newItem.x))
        stations[type[1]].station.position_y.set(Number(newItem.y))
        stations[type[1]].station.width.set(Number(newItem.w))
        stations[type[1]].station.height.set(Number(newItem.h))
      }

      if (type[0] == 'divisors') {
        divisors[type[1]].position_x.set(Number(newItem.x))
        divisors[type[1]].position_y.set(Number(newItem.y))
        divisors[type[1]].width.set(Number(newItem.w))
        divisors[type[1]].height.set(Number(newItem.h))
      }
    })
  }

  const handleSaveChange = () => {
    const newStations = stations.get().map((item) => item.station)
    const contactCenterId = station().get()?.contact_center?.id

    try {
      saveStationsPositions(contactCenterId, newStations)
      .then(() => notify({ message: `${translate('Stations position saved successfully!')}`, type: 'success'}))

      saveStationsManagementTags(contactCenterId, divisors.get())
      .then(() => notify({ message: `${translate('Tags position saved successfully!')}`, type: 'success' }))
    } catch {
      notify({ message: `${translate('Something went wrong! Try again')}`, type: 'error' })
    }
  }

  const handleAgreeDeleteTag = (olderTag) => {

    try {
      if(olderTag.id){
        deleteTag(removedTag.attach(Downgraded).get().id)
      } 
      
      const newTags = divisors.attach(Downgraded).get().filter( tag => tag != olderTag)
      divisors.set(newTags)

      notify({
        message: `${translate('Tag delete successfully!')}`,
        type: 'success',
      })

    } catch {
      notify({
        message: `${translate('Something went wrong! Try again')}`,
        type: 'error',
      })
    }
   
    
      
    openDialog.set(false)
  }

  return (
    <Layout marginLayout={true}>
      <InternalHeader
        title={translate(`Management Stations`)}
        action={
          <>
            <IconButton
              title={translate('Add Tag')}
              disabled={false}
              onClick={() => divisorsListOpen.set(true)}
            >
              <BookmarksIcon />
            </IconButton>
            <IconButton
              title={translate('Save')}
              onClick={() => handleSaveChange()}
            >
              <SaveIcon />
            </IconButton>
          </>
        }
      />

      <InternalContent title={`${translate('Filters')}`} expandButton={false}>
        <ResponsiveGridLayout
          className="layout"
          compactType="vertical"
          mounted={false}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 8, sm: 6, xs: 4, xxs: 2 }}
          onLayoutChange={(currentLayout) => handleChangeLayout(currentLayout)}
        >
          {stations.get().map((item, index) => (
            <div
              key={'station-' + index}
              data-grid={{
                x: Number(item.station.position_x) ?? index,
                y: Number(item.station.position_y) ?? 0,
                w: Number(item.station.width) ?? 2,
                h: Number(item.station.height) ?? 1,
                minH: 1,
                maxH: 1,
              }}
            >
              <StationCardMonitor
                station={item.station}
                user={item.user}
                stationIsOnline={item.station_is_online}
              />
            </div>
          ))}
          {divisors.get().map((divisor, index) => (
            <div
              key={'divisors-' + index}
              data-grid={{
                x: Number(divisor.position_x) ?? 0,
                y: Number(divisor.position_y) ?? 0,
                w: Number(divisor.width) ?? 2,
                h: Number(divisor.height) ?? 0.3,
                minH: 0.3,
                maxH: 0.3,
              }}
            >
              <Card
                variant="outlined"
                sx={{ alignItems: 'center', textAlign: 'center' }}
              >
                {divisor?.name ? divisor?.name : ''}
              </Card>
            </div>
          ))}
        </ResponsiveGridLayout>
      </InternalContent>

      <Dialog
        fullWidth={true}
        open={divisorsListOpen.get()}
        onClose={() => divisorsListOpen.set(false)}
      >
        <DialogTitle>
          <Grid sx={{ alignItems: 'center', textAlign: 'center' }}>
            {translate('Tags')}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              mt: 2,
            }}
          >
            <Grid item xs={10}>
              <TextField
                id="new-divisors"
                size="small"
                label={translate('New Tag')}
                variant="outlined"
                value={newDivisors.get()}
                onChange={(event) => {
                  newDivisors.set(event.target.value)
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                title={translate('Add')}
                disabled={false}
                onClick={() => {
                  divisors.merge([
                    {
                      name: newDivisors.get(),
                      position_x: 0,
                      position_y: 0,
                      width: 2,
                      height: 0.3,
                    },
                  ])
                  newDivisors.set('')
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
          <List
            sx={{
              width: '100%',
              height: 'auto',
              overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
          >
            {divisors.get().map((divisor, index) => {
              return (
                <ListItem
                  key={index}
                  role="listitem"
                >
                  <Chip
                    label={`${divisor.name}`}
                    onDelete={() => {
                      removedTag.set(divisors[index].attach(Downgraded).get())
                      tagIndex.set(index)
                      openDialog.set(true)
                    }}
                    variant="outlined"
                    sx={{justifyContent: 'space-between', fontSize: 16, marginLeft: -2}}
                  />

                </ListItem>
              )
            })}
            <ListItem />
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => divisorsListOpen.set(false)}>
            {translate('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        handleAgree={() => handleAgreeDeleteTag(removedTag.attach(Downgraded).get())}
        handleClose={() => openDialog.set(false)}
        open={openDialog}
        title={translate('Confirmation')}
        content={`${translate('Confirm the delete of the tag')} "${removedTag.attach(Downgraded).get().name}"?`}
        loading={loading}
        AgreeLabel={translate('Ok')}
      />
    </Layout>
  )
}
