import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import useEntryState from '~/features/Entry/stores/EntryState';
import { toast } from 'react-toastify';


interface IClickableNotification {
  readonly message: string,
  readonly clickableMessage?: {
    readonly id?: number | null
    readonly action?: any
    readonly urlRedirection?: string
    readonly hideButton?: boolean
  } | null
}

export default function ClickableNotification({ message, clickableMessage }: IClickableNotification) {
  return (
    <>
      {message}
      &nbsp;
      {
        clickableMessage && (clickableMessage?.urlRedirection == window.location.pathname && window.location.pathname == '/dispatch' || window.location.pathname == '/dispatch/dispatches/list-full' && clickableMessage.hideButton === false ||  clickableMessage?.urlRedirection == '/dispatch' && (window.location.pathname.includes('/window-support'))) ? 
          <Button 
          onClick={() => {clickableMessage?.action(clickableMessage?.id)}} 
          variant='outlined'
          sx={
            {
              color: 'text.primary',
              borderColor: 'text.primary',
              '&:hover': {
                color: 'text.primary',
                borderColor: 'text.primary',
              },
            }
          }
        >{'Abrir'}</Button> : clickableMessage && window.location.pathname == '/entry' && clickableMessage.hideButton === false && clickableMessage?.urlRedirection ? <Link to={`${clickableMessage?.urlRedirection}`}>
          {
            <Button
              onClick={() => clickableMessage?.action(clickableMessage?.id)}
              variant='outlined'
              sx={
                {
                  color: 'text.primary',
                  borderColor: 'text.primary',
                  '&:hover': {
                    color: 'text.primary',
                    borderColor: 'text.primary',
                  },
                }
              }
            >
              {'Abrir'}
            </Button>
          }
        </Link> : <></>
      }
    </>
  );
}