import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useLang } from '~/hooks/useLang';
import { Box, Grid } from '@mui/material';
import useDispatchRegistrationState from '~/features/Dispatch/stores/DispatchRegistrationState';

interface IFormDialog {
  readonly open: boolean;
  readonly handleAbort: () => void;
  readonly handleConfirm: () => void;
  readonly actionName?: string;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDelete({ handleAbort, handleConfirm, open, actionName }: IFormDialog) {
  const { registrationDispatch } = useDispatchRegistrationState();
  const {
    addRegistration,
    externalAction,
  } = registrationDispatch();

  const action = actionName ? actionName[0]?.toLocaleUpperCase() + actionName?.slice(1) : ''
  const { translate } = useLang();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleAbort}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {translate(`${action}`)}
        </DialogTitle>
        <DialogContent>
          {addRegistration.get() && externalAction.get() ?
            <DialogContentText>
              {translate(`Mudando o despacho atual você perderá as informações inseridas. Deseja sair do despacho atual?`)}
            </DialogContentText>
            : <DialogContentText>
              {translate(`Do you really want to ${actionName}`)}
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 2,
              marginBottom: '8px'
            }}>
              <Button
                variant="outlined"
                autoFocus
                onClick={handleAbort}>
                {translate('No')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
              >
                {translate('Yes')}
              </Button>
            </Box>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}