import React, { ChangeEvent, useEffect } from 'react';
import { useLang } from '~/hooks/useLang';
import { TextField, Grid, IconButton, Typography, Divider, CircularProgress, ListItem, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchVehicleService } from '~/features/Vehicle/services/index';
import { useHookstate, createState, useState, Downgraded } from '@hookstate/core';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import InfiniteScroll from 'react-infinite-scroll-component';
import { validPlate } from '~/utils/validators';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useOccurrenceCommentState from '~/features/Occurrence/stores/OccurrenceCommentState';
import useSystemState from '~/features/System/stores/SystemState';
import { Visibility } from '@mui/icons-material';

export interface VehicleListProps {
  readonly id: number
  readonly plate: string
  readonly occurrence_id: number
  readonly circunstance: {
    readonly id: number
    readonly name: string
  }
  readonly destination: {
    readonly id: number
    readonly name: string
  }
  readonly vehicle_type: {
    readonly id: number
    readonly name: string
  }
  readonly vehicle_model: {
    readonly id: number
    readonly name: string
  }
  readonly vehicle_color: {
    readonly id: number
    readonly name: string
  }
  readonly vehicle_brand: {
    readonly id: number
    readonly name: string
  }
  readonly occurrence: {
    readonly type: {
      readonly name: string
    },
    readonly dispatches: Array<{
      readonly id: number,
      readonly code: string
    }>
  }
}

const plateState = createState('');

export function useSearchVehicle() {
  const plate = useState(plateState);

  return ({
    plate: () => plate
  })
}

export default function SearchVehicle() {
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  
  const plateExternal = useSearchVehicle().plate;
  const plate = useHookstate('');
  const plateSearch = useHookstate('');
  const vehicles = useHookstate<readonly VehicleListProps[]>(windowManager().searchVehicle.dataSearchVehicle.attach(Downgraded).get() ?? []);
  const vehiclesCount = useHookstate(0);
  const { syncOccurrenceEditAttendent } = useOccurrenceState();
  const { syncPreviousComments } = useOccurrenceCommentState();

  useEffect(() => {
    plate.set(plateExternal().get());
    return () => {
      plateExternal().set('');
    }
  }, []);

  const nextData = async (reset = false) => {
    const pageQuery = reset ? 0 : Math.floor(vehicles.get().length / 10);
    searchVehicleService({ plate: plateSearch.get(), page: pageQuery, limit: 10 }).then(({ data, rowsCount }) => {
      if (reset) {
        vehicles.set(data);
        vehiclesCount.set(rowsCount);
      } else {
        vehicles.merge(data);
        vehiclesCount.set(rowsCount);
      }
    })
  }

  const onClick = (id) => {
    syncOccurrenceEditAttendent(id);
    syncPreviousComments(id);
    window.location.pathname == '/dispatch' ? windowManager()['dispatchesResultSearches'].open.set(true) : windowManager().occurrence.open.set(true);
  }

  useEffect(() => {
    if(!plate.get()?.length){
      windowManager()['dispatchesResultSearches'].open.set(false)
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container spacing={2}>
        <Grid container padding={1}>
          <Grid item xs={12} padding={1}>
            <Grid container xs={12} direction="row" spacing={1} padding={1}>
              <Grid item xs={6} spacing={1}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 7 }}
                  label={`${translate('Plate')}`}
                  size="small"
                  value={plate.get() ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => { plate.set(event.target.value) }}
                />
              </Grid>
              <Grid item xs={6} spacing={1}>
                <IconButton onClick={() => {
                  plateSearch.set(plate.get());
                  nextData(true)
                }} disabled={!validPlate(plate.get())}>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />

            <Grid container padding={1}>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={vehicles.value.length}
                  next={() => nextData()}
                  hasMore={(vehicles.value.length != vehiclesCount.get()) && vehicles.value.length != 0}
                  height={335}
                  loader={<CircularProgress />}
                >
                  {vehicles.value.length != 0 ? (
                    vehicles.get()?.map((items) => (
                      <>
                        <ListItem key={items.id}>
                          <ListItemText primary={`OC: ${items?.occurrence_id} | ${items?.occurrence.dispatches?.map((item, index) => `\n\n${item.code}`)}`}
                            secondary={<>
                              <Typography variant={'subtitle2'}>
                                {items?.occurrence.type.name}
                              </Typography>
                              <Typography variant={'subtitle2'}>
                                {items?.circunstance?.name}
                              </Typography>
                                
                              <Typography variant={'subtitle2'}>
                                Destino Final: {items?.destination?.name}
                              </Typography>
                              <Typography variant={'subtitle2'}>
                                {items?.plate} {items?.vehicle_type?.name}
                              </Typography>
                              <Typography variant={'subtitle2'}>
                                {items?.vehicle_brand?.name} 
                                {items?.vehicle_model?.name} 
                                {items?.vehicle_color?.name ? '- ' + items?.vehicle_color?.name : false}
                                </Typography>
                            </>} />
                          <IconButton aria-label="comment" onClick={() => onClick(items?.occurrence_id)} title='Visualizar'>
                            <Visibility />
                          </IconButton>
                        </ListItem>
                        <Divider sx={{ marginTop: 2 }} />
                      </>
                    ))
                  ) : <Grid xs={12} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography>
                      {translate('No Vehicles')}
                    </Typography>
                  </Grid>}
                </InfiniteScroll>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}
