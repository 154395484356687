import { TextField, Box, Typography, Card, CardActions, CardHeader, InputAdornment, IconButton, FilledInput, OutlinedInput } from '@mui/material';
import React, { useEffect } from 'react';
import { useLang } from '~/hooks/useLang';
import { useHookstate, useState } from '@hookstate/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useUserState from '~/features/User/stores/UserState';
import { changePasswordService } from '~/features/User/services/index';
import { LoadingButton } from '@mui/lab';
import * as zod from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import notify from '~/utils/notify';
import { useAuth } from '~/features/auth/hooks/useAuth';
import dayjs from 'dayjs';

const regexUperCase = /[A-Z]/
const regexLowerCase = /[a-z]/
const regexNotNumber = /[.\d]/
const regexEspecialCaracter = /[@$!%?&*#]/


const changePasswordSchema = zod.object({
  newPassword: zod.string()
    .refine((value) => value.length >= 8, 'Deve ter 8 ou mais caracteres')
    .refine((value) => regexLowerCase.test(value), 'Deve ter 1 letra minúscula')
    .refine((value) => regexUperCase.test(value), 'Deve ter 1 letra maiúscula')
    .refine((value) => regexNotNumber.test(value), 'Deve ter 1 número')
    .refine((value) => regexEspecialCaracter.test(value), 'Deve ter 1 carater especial'),
  confirmNewPassword: zod.string()
}).refine((value) => value.newPassword === value.confirmNewPassword, {
  message: "As senhas não coincidem",
  path: ["confirmPassword"],
})

type changePasswordFormData = zod.infer<typeof changePasswordSchema>

// eslint-disable-next-line max-lines-per-function
export default function ChangePasswordForm() {
  const { translate } = useLang();
  const history = useHistory();
  const { user } = useUserState();
  const location = useLocation()
  const { login } = useParams<{ login?: string }>();
  const isMe = (location.pathname === '/user/change-password/me' || user().login.get() === login);
  const timeOutRedirectDelay = 650;
  const loading = useState(false);
  const userName = useHookstate<string | null | undefined>(null);
  const showNewPassword = useHookstate(false);
  const showConfirmPassword = useHookstate(false);
  const errorMin8 = useHookstate(false)
  const errorUperCase = useHookstate(false)
  const errorLowerCase = useHookstate(false)
  const errorNotNumber = useHookstate(false)
  const errorCaracterEspecial = useHookstate(false)
  const { logout } = useAuth();
  const today = dayjs()
  
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    criteriaMode: "all"
  })


  async function handleChangePassword(data: changePasswordFormData) {
    loading.set(true);

    if (!login && location.pathname != '/user/change-password/me') throw new Error(translate('Without login Parameter'));

    if (location.pathname == '/user/change-password/me') {
      userName.set(user().login.get())
    } else {
      userName.set(isMe ? user().login.get() : login);
    }

    changePasswordService({
      login: userName.get(),
      password: data.newPassword,
      repassword: data.confirmNewPassword,
      isMe: isMe
    }).then((status) => {
      handlePushToIndex();
      setTimeout(() => {
        Number(status) == 200 && isMe && logout()
      }, 2000)
    }).catch((err) => console.error(err)).finally(() => loading.set(false))

  }

  useEffect(() => {
    if(user().need_to_change_password.get()){
      notify({message: translate('First acess: It is necessary to change the password!'), type:'warning'})
    }

    if(dayjs(user().password_expiration_date.get()) <= today){
      notify({message: translate('Password expired, create a new one!'), type:'warning'})
    }

    return () => {
      if(user().need_to_change_password.get() || (dayjs(user().password_expiration_date.get()) <= today)){
        setTimeout(() => {
          history.push('/user/change-password/me')
        }, 4000)
      }
    }

  }, [])

  const handlePushToIndex = () => {
    return setTimeout(() => { loading.set(false); isMe ? history.push('/') : history.push('/user'); }, timeOutRedirectDelay);
  }

  const watchNewPassword = watch("newPassword", '')

  useEffect(() => {
    errorMin8.set(watchNewPassword.length >= 8);
    errorUperCase.set(regexUperCase.test(watchNewPassword));
    errorLowerCase.set(regexLowerCase.test(watchNewPassword));
    errorNotNumber.set(regexNotNumber.test(watchNewPassword));
    errorCaracterEspecial.set(regexEspecialCaracter.test(watchNewPassword));
  }, [watchNewPassword]);

  const handleClickShowNewPassword = () => {
    showNewPassword.set(!showNewPassword.get())
  }

  const handleClickShowConfirmPassword = () => {
    showConfirmPassword.set(!showConfirmPassword.get())
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Card
      sx={{
        width: '30%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
      }}
    >
      <CardHeader
        title={
          <Typography
            variant='h5'
            gutterBottom
            component="div"
            sx={{ textAlign: 'center' }} >
            {` ${isMe || location.pathname === '/user/change-password/me' ? translate('Change Your Password') : `${translate('Change Password')} ${login && (`${translate('From').toLowerCase()} ${login}`)} `} `}
          </Typography>
        }
        subheader={                        
         isMe && <Typography color={'red'}>
            {translate('When changing your password, you will be redirected to log in again.')}
          </Typography>
      }
      />
      <Box
        sx={{
          width: '100%',
          paddingX: 4,
        }}>

        <form
          onSubmit={handleSubmit(handleChangePassword)}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <OutlinedInput
              size='medium'
              // variant="outlined"
              type={showNewPassword.get() ? 'text' : 'password'}
              fullWidth
              placeholder={translate('New Password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              {...register('newPassword')}
            />
            <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'start'} justifyContent={'center'}>
              <Typography color={errorMin8.get() ? 'green' : 'red'}>
                {translate('Must be 8 or more characters long')}
              </Typography>
              <Typography color={errorUperCase.get() ? 'green' : 'red'}>
                {translate('Must have 1 capital letter')}
              </Typography>
              <Typography color={errorLowerCase.get() ? 'green' : 'red'}>
                {translate('Must have 1 lowercase letter')}
              </Typography>
              <Typography color={errorNotNumber.get() ? 'green' : 'red'}>
                {translate('Must have 1 number')}
              </Typography>
              <Typography color={errorCaracterEspecial.get() ? 'green' : 'red'}>
                {translate('Must have 1 special character')}
              </Typography>
            </Box>
            <OutlinedInput
              size='medium'
              fullWidth
              type={showConfirmPassword.get() ? 'text' : 'password'}
              placeholder={translate('Confirm new Password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              {...register('confirmNewPassword')}
            />

            <ErrorMessage
              errors={errors}
              name="confirmPassword"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => {
                  return (
                    <Typography key={type} color={'red'} width={'100%'}>
                      {translate('Passwords do not match')}
                    </Typography>
                  )
                })
              }
            />
          </Box>
          <CardActions
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'flex-end',
              padding: '0px 0px 16px 0px',
              marginTop: '24px'
            }}
          >
            <LoadingButton
              loading={loading.get()}
              variant='outlined'
              size='small'
              onClick={handlePushToIndex}
            >
              {translate('Cancel')}
            </LoadingButton>
            <LoadingButton
              loading={loading.get()}
              variant='contained'
              size='small'
              type='submit'
              onClick={() => ''}
            >
              {translate('Save')}
            </LoadingButton>
          </CardActions>
        </form>
      </Box>
    </Card >
  )
}