
import React, {createContext, ReactNode, useEffect, useMemo, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import authLogin from '../services';
import useSocket from '../../../hooks/useSocket';
import IUser from '~/features/User/interfaces/IUser';
import IStation from '~/features/Station/interfaces/IStation';
import { authFetch } from '~/services/fetch';
import constants from '~/core/constants';
import useCookie from '../../../hooks/useCookie';
import useUserState from '~/features/User/stores/UserState';
import useStationState from '~/features/Station/stores/StationState';
import { toast } from "react-toastify";
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import notify from '~/utils/notify';
import i18n from '~/i18n';

type AuthContextType = {
  readonly signed: boolean;
  readonly loading: boolean;
  readonly error?: string | null;
  readonly user: IUser
  readonly signIn: (username: string, password: string) => void;
  readonly logout: (ignoreValidationEntries?: boolean) => Promise<unknown>;
  readonly onlyReadMode: boolean;
}

type ResponseAuthType = {
  readonly user: IUser
  readonly token: string;
  readonly station: IStation;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

type AuthContextProviderProps = {
  readonly children: ReactNode;
}

import Cookies from "universal-cookie";
import dayjs from 'dayjs';
const cookies = new Cookies();

export function AuthContextProvider({children}: AuthContextProviderProps) {
  const [ error, setError ] = useState<string | null>(null);
  const { user, resetUser, setUserCookie } = useUserState();
  const { setStationCookie } = useStationState();
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ signed, setSigned ] = useState<boolean>(false);
  const [ , setToken ] = useCookie( constants.keys.token );
  const { socket } = useSocket();
  const history = useHistory();
  const location = useLocation();
  const messageDurationInMs = 4000;
  const [ onlyReadMode, setOnlyReadMode ] = useState<boolean>(false);
  const today = dayjs()

  const { clear: clearToast } = useNotificationCenter();

  const [anonymousKey] = useCookie(constants.keys.cookies.anonymousIdentificationKey);

  useEffect(() => {
    let active = true;
    
    const idTimeout = setTimeout(() => { if(active && error) setError(null); },messageDurationInMs);

    setTimeout(() => {
      if(user().need_to_change_password.get()){
        history.push('/user/change-password/me')
      }
    }, 4000)

    return () => {
      active = false;
      if(idTimeout) clearTimeout(idTimeout);
    }
  }, [error, location.pathname]);

  function signIn(login: string, password: string) {
    setLoading(true);

    authLogin({login, password})
      .then((response: ResponseAuthType) => {
        if(response.token) {
          setToken(response.token);
          user().set(response.user);
          setUserCookie(response.user);
          console.log('STATION: ', response.station)
          setStationCookie(response.station);
          setSigned(true);
          console.info('Calling connect');
          socket.connect();

          setOnlyReadMode(!Object.keys(response.station).length)
          
          toast.dismiss();
          clearToast();

          if((response.user.need_to_change_password && !response.user.password_expiration_date) || (dayjs(response.user.password_expiration_date) <= today)){
            history.push('/user/change-password/me')
          } else {
            history.push('/');
          }

          if(response.user.password_expiration_date && dayjs(response.user.password_expiration_date).subtract(2, 'month') <= today && (dayjs(response.user.password_expiration_date) > today)){
            notify({message: `Sua senha irá expirar no dia ${dayjs(response.user.password_expiration_date).format('DD/MM/YYYY')}, altere-a antes disso.`, type:'warning'})
          }
        }
      })
      .catch((err) => {
        setError(err ?? '');
      })
      .finally(() =>{ setLoading(false); });
  }

  async function logout(ignoreValidationEntries = true): Promise<unknown> {
    // try {
    //   const response = await authFetch({ url: '/auth/logout', data: { ignoreValidationEntries: ignoreValidationEntries }});

    //   resetUser();
    //   socket.disconnect();

    //   cookies.remove(constants.keys.token);

    //   return history.push(anonymousKey ? '/login/no-station' : '/login')

    // } catch (error) {
    //   throw error;
    // }

    return new Promise((resolve, reject) => {
      const token = cookies.get(constants.keys.token)

      if (!token) return resolve(history.push(anonymousKey ? '/login/no-station' : '/login'));
      
      authFetch({ url: '/auth/logout', data: { ignoreValidationEntries: ignoreValidationEntries }})
      .then(() => {
        resetUser();
        socket.disconnect();
        cookies.remove(constants.keys.token);
        return resolve(history.push(anonymousKey ? '/login/no-station' : '/login'));
      }).catch(reject);
    });
  }  
  
  const memoedValue = useMemo(():AuthContextType => ({
    signed,
    user: user().get(),
    loading,
    error,
    signIn,
    logout,
    onlyReadMode
  }), [loading, error, onlyReadMode]);
  
  return (
    <AuthContext.Provider value={memoedValue}>
      { children }
    </AuthContext.Provider>
  );
}
