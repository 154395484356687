import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';

interface FieldAutocompleteRegistration {
    readonly onValueChange: (value: IOptions | null) => void;
    readonly onError?: (value: boolean) => void;
    readonly optionsFetch?: <T = Record<string, string>>(id?: string) => Promise<T[]>;
    readonly optionsList?: readonly IOptions[]
    readonly error?: boolean;
    readonly disabled?: boolean
    readonly isRequired?: boolean;
    readonly label: string;
    readonly depends?: string;
    readonly valueId: string;
    readonly filter?: string;
}

type IOptions = {
    readonly id: string;
    readonly name: string;
    readonly uf?: string;
    readonly force_one_unit?: string;
    readonly weapon_type_id?: string;
    readonly symbol?: string;
}

export default function FieldAutocompleteRegistration({ optionsList, valueId, depends, onError, isRequired = false, error, label, disabled, onValueChange, optionsFetch, filter }: FieldAutocompleteRegistration) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly IOptions[]>([]);
    const [value, setValue] = useState<IOptions | null>(null);

    useEffect(() => {
        if (!optionsFetch) {
            setOptions(optionsList ?? [])
            return
        }
        if (depends) {
            setValue(null)
            optionsFetch(depends).then((responses) => {
                setOptions(responses.map(response => ({
                    id: response.id,
                    name: response.name,
                    uf: response.uf,
                    weapon_type_id: response.weapon_type_id,
                    force_one_unit: response.force_one_unit,
                    symbol: response.symbol,
                })));
            })
        } else {
            optionsFetch().then((responses) => {
                setOptions(responses?.map(response => ({
                    id: response.id,
                    name: response.name,
                    uf: response.uf,
                    weapon_type_id: response.weapon_type_id,
                    force_one_unit: response.force_one_unit,
                    symbol: response.symbol,
                })));
            })
        }
    }, [depends])


    useEffect(() => {
        if (!valueId) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === valueId) ?? null)
        }
    }, [options, valueId])

    useEffect(() => {
        if (!optionsFetch) {
            setOptions(optionsList ?? [])
            return
        }

        setValue(null)
        optionsFetch().then((responses) => {
            setOptions(responses?.map(response => ({
                id: response.id,
                name: response.name,
                uf: response.uf,
                weapon_type_id: response.weapon_type_id,
                force_one_unit: response.force_one_unit,
                symbol: response.symbol,
            })));
        })

    }, [filter])


    return (
        <Autocomplete
            autoSelect
            id={`filter-${label}`}
            disabled={disabled}
            options={options}
            value={value}
            noOptionsText={translate('No options')}
            clearText={translate('Clear')}
            size='small'
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option: IOptions) => option.name}
            onChange={(_, type) => {
                setValue(type)
                onValueChange(type)
            }}
            onFocus={() => {
                if (!value && isRequired && onError) {
                    onError(true)
                }
            }}
            onClose={() => {
                if (!value && isRequired && onError) {
                    onError(false)
                }
            }}
            onBlur={() => {
                if (!value && isRequired && onError) {
                    onError(true)
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={!!error}
                    onFocus={() => {
                        if (onError) {
                            onError(false)
                        }
                    }}
                    required={isRequired}
                    label={label}
                />
            )}
        />
    )
}