/* eslint-disable max-lines-per-function */
/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect } from 'react';
import { CardActions, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import Layout from '~/components/Layout';
import InternalContent from '~/components/Layout/components/InternalContent';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import { useLang } from '~/hooks/useLang';
import { getAllAreas, sendAreaConfig, sendMultipleAreaConfig } from '../services';
import { useHookstate } from '@hookstate/core';
import SelectBasic from '~/components/SelectBasic';
import ButtonForm from '~/components/ButtonForm';
import LoopIcon from '@mui/icons-material/Loop';
import { useHistory } from 'react-router-dom';
import AreasTreeView, { RenderTree } from '../components/AreasTreeView/';
import { getAllDisptachGroup } from '~/features/DispatchGroup/services';
import { getAllAgencies } from '~/features/Agency/services';
import notify from '~/utils/notify';
import { AreaErrs, loadedObject } from '../interfaces/IAreaConfig';
import FilterAutocompleteDispatchGroup from '~/components/FilterAutocompleteDispatchGroup';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';

export default function AreaConfigCreate() {
  const { translate } = useLang();
  const history = useHistory();
  const processing = useHookstate<number>(0);
  const hideGroups = useHookstate(false);

  const areaId = useHookstate<string | null>(null);
  const areasOptions = useHookstate<Array<loadedObject | null>>([]);

  const disptachGroupOptions = useHookstate<(loadedObject | null)[]>([]);
  const disptachGroupId = useHookstate<number | null>(null);

  const agencyId = useHookstate<number | null>(null);
  const agencies = useHookstate<(loadedObject | null)[]>([]);

  const erros = useHookstate<AreaErrs>({
    agency: '',
    area: '',
    dispatchGroup: '',
    type: ''
  });

  const typesAndSubtypes = useHookstate<RenderTree>({
    id: 'root',
    name: translate('ALL'),
    description: '',
    selected: false,
    parent: '',
    children: [],
    randomId: ''
  })

  const typeOurSubtypeRequest = useHookstate<RenderTree>({
    id: 'root',
    name: translate('ALL'),
    description: '',
    selected: false,
    parent: '',
    children: [],
    randomId: ''
  })

  const handleCancelButton = () => {
    history.push('/area-config');
  }

  const checkRequired = (childrenMap) => {

    if (!agencyId.get()) {
      erros.set({ ...erros.get(), agency: 'Inform a agency' })
      return true
    }
    if (!hideGroups.get() && !disptachGroupId.get()) {
      erros.set({ ...erros.get(), dispatchGroup: 'Inform a dispatch group' })
      return true
    }
    if (!hideGroups.get() && !areaId.get()) {
      erros.set({ ...erros.get(), area: 'Inform a area' })
      return true
    }
    if (!childrenMap.length) {
      erros.set({ ...erros.get(), type: 'Inform a type' })
      return true
    }
    return false
  }

  // const checkSeleted = (childrenRoot) => {
  //   return childrenRoot?.map((chi) => {
  //     const children = chi.children
  //     if (children?.length) {
  //       return children.filter(ch => ch.selected).map(ch => ({ id: parseInt(ch.id), parent: parseInt(ch.parent) }))
  //     } else if (chi.selected) {
  //       return [{ id: parseInt(chi.id), parent: undefined }]
  //     }
  //   }).filter(chi => chi?.length).flat(1)

  // }
  
  const checkSeleted = (childrenRoot) => {
    let result: any = [];
    childrenRoot?.map((type) => {

      const subtype = type.children
      if (subtype?.length) {
        if (type.selected == true) {
          result = result.concat({ id: parseInt(type.id), parent: undefined })
        }
        subtype.filter(ch => ch.selected == true).map(cha => {
          result = result.concat({ id: parseInt(cha.id), parent: parseInt(cha.parent) })
        })
      } else {
        if (type.selected == true) {
          result = result.concat({ id: parseInt(type.id), parent: undefined })
        }
      }
    })

    return result
  }

  const handleSubmit = () => {
    const childrenRoot = typesAndSubtypes.get().children;
    const childrenMap = checkSeleted(childrenRoot)
    erros.set({ ...erros.get(), type: '' })

    if (checkRequired(childrenMap)) {
      return
    }

    if (hideGroups.get()) {
      sendMultipleAreaConfig({
        agencyId: agencyId.get(),
        configTypes: childrenMap.map(chi => ({ type_id: chi?.parent ? chi?.parent : chi?.id as number, subtype_id: chi?.parent ? chi?.id : null }))
      }).then(() => {
        notify({ message: translate('Area configured successfully'), type: 'success' })
      }).finally(handleCancelButton)
    } else {
      childrenMap?.forEach(chi => {
        sendAreaConfig({
          agencyId: agencyId.get(),
          areaId: areaId.get(),
          dispatchGroupId: disptachGroupId.get(),
          typeId: chi?.parent ? chi?.parent : chi?.id as number,
          subtypeId: chi?.parent ? chi?.id : null
        }).then(() => {
          notify({ message: translate('Area configured successfully'), type: 'success' })
        }).finally(handleCancelButton)
      })
    }
  }

  useEffect(() => {
    getAllAreas().then(areas => {
      areasOptions.set(areas.map(areaMap => ({ id: areaMap.id, name: areaMap.name })))
    })
  }, [])


  return (
    <Layout marginLayout={true}>
      <InternalHeader title={translate('Area Configuration')} />
      <InternalContent expandButton={false}>

        <Grid xs={12} container sx={{ alignContent: 'center', justifyContent: 'flex-start' }}>
          <Grid item xs={12} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              {translate('Agency:name')}
            </Typography>
          </Grid>
          <Grid container xs={12} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Grid item xs={3} sx={{ pl: 25 }}>
              <Typography variant='body2'>
                {translate('Select')}
                <span style={{ color: 'red', fontSize: 16 }}>
                  *
                </span>
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 3 }}>
              <FilterAutocompleteAgency
                agencyId={agencyId.get()}
                onAgencyChange={(agency) => {
                  agencyId.set(agency);
                  disptachGroupId.set(null)
                  erros.set({ ...erros.get(), agency: '' })

                }}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 3 }}>
              <FormControlLabel control={<Switch

                checked={hideGroups.get()}
                onChange={() => {
                  hideGroups.set(!hideGroups.get())
                }}
                inputProps={{ 'aria-label': 'controlled' }}

                defaultChecked />} label={translate('Link by Agencies')} />
            </Grid>
          </Grid>
          {!hideGroups.get() && (
            <>
              <Grid item xs={12} sx={{ padding: 3 }}>
                <Typography variant="h6" gutterBottom>
                  {translate('Dispatch Group')}
                </Typography>
              </Grid>
              <Grid container xs={12} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Grid item xs={3} sx={{ pl: 25 }}>
                  <Typography variant='body2'>
                    {translate('Select')}
                    <span style={{ color: 'red', fontSize: 16 }}>
                      *
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 3 }}>
                  <FilterAutocompleteDispatchGroup
                    agencyId={agencyId.get() ?? undefined}
                    dispatchGroupId={disptachGroupId.get() ?? undefined}
                    onGroupDispatchChange={(disptachGroupValue) => {
                      disptachGroupId.set(disptachGroupValue);
                      erros.set({ ...erros.get(), dispatchGroup: '' })
                    }}
                  />
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ padding: 3 }}>
                {translate('Area')}
              </Typography>
              <Grid container xs={12} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Grid item xs={3} sx={{ pl: 25 }}>
                  <Typography variant='body2'>
                    {translate('Select')}
                    <span style={{ color: 'red', fontSize: 16 }}>
                      *
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 3 }}>
                  <SelectBasic
                    state={areaId}
                    options={areasOptions.get()}
                    placeholder={translate('Area')}
                    disabled={false}
                    error={erros.area}
                    size={'small'} />
                </Grid>
              </Grid>
            </>)}

          <Grid item xs={12} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              {translate('Type and Subtype')}
            </Typography>
          </Grid>
          <Grid container xs={12} sx={{ pb: 1 }}>
            <Grid item xs={3} sx={{ pl: 25 }}>
              <Typography variant='body2'>
                Marque
                <span style={{ color: 'red', fontSize: 16 }}>
                  *
                </span>
              </Typography>

              {!!erros.type.get() && <Typography color='red' variant='body2'>
                {' '}
                {erros.type.get()}
              </Typography>}
            </Grid>
            <Grid item xs={4.5}>
              <Typography variant='body2'>
                Tipo e Subtipo
              </Typography>

            </Grid>
            <Grid item xs={4}>
              <Typography variant='body2'>
                {translate('Description')}
              </Typography>
            </Grid>
          </Grid>
          <AreasTreeView 
          error={erros.type.get()} 
          typesAndSubtypes={typesAndSubtypes} 
          typeOurSubtypeRequest={typeOurSubtypeRequest}
          />

        </Grid>
        <CardActions disableSpacing sx={{ pt: 4 }}>
          <ButtonForm
            type='cancel' 
            onClick={handleCancelButton}>
            {translate('Cancel')}
          </ButtonForm>
          <ButtonForm 
            onClick={handleSubmit} 
            type='submit'>
            <LoopIcon className='load-icon' sx={{ display: processing.get() ? 'block' : 'none' }} />
            {processing.get() ? translate('Saving') : translate('Save')}
          </ButtonForm>
        </CardActions>
      </InternalContent>
    </Layout >
  )
}