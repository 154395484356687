import React, { useEffect, useState } from 'react'
import { useHookstate } from '@hookstate/core'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import IType from '~/features/Type/interfaces/IType'
import { getAllTypes } from '~/features/Type/services'

interface FilterAutoCompleteTypeProps {
  readonly onTypeChange: (value) => void
  readonly categoryId?: number | number[]
  readonly hideCode?: boolean
  readonly typeId?: readonly number[]
  readonly disabled?: boolean
  readonly error?: boolean
}

export default function FilterAutocompleteTypes({
  disabled,
  typeId,
  onTypeChange,
  hideCode = false,
  categoryId,
  error,
}: FilterAutoCompleteTypeProps) {
  const { translate } = useLang()
  const [options, setOptions] = useState<readonly IType[] | undefined>([])
  const [optionsFilter, setOptionsFilter] = useState<readonly IType[]>([])

  const [value, setValue] = useState<IType[]>([])
  const loading = useHookstate(false)

  useEffect(() => {
    getAllTypes().then((types) => {
      const resTypes = types.map((type) => ({
        ...type,
        displayName: hideCode ? `${type.name}` : `[${type.code}] ${type.name}`,
        category_id: type.category_id ?? null,
      }))

      setOptions(resTypes)
      setOptionsFilter(resTypes)
    })
  }, [])

  useEffect(() => {
    if (!typeId?.length) {
      setValue([])
    }
  }, [typeId?.length])

  useEffect(() => {
    if (Array.isArray(categoryId) && categoryId.length && options) {
      const filter =
        options.filter(
          (typeMap) =>
            categoryId.includes(typeMap.category_id as number) &&
            typeMap.category_id
        ) ?? []
      setOptionsFilter(filter.length ? filter : [])
    } else if (!Array.isArray(categoryId) && categoryId && options) {
      const filter =
        options.filter((typeMap) => typeMap.category_id === categoryId) ?? []
      setOptionsFilter(filter.length ? filter : [])
    } else {
      setOptionsFilter(options ?? [])
    }
  }, [categoryId])

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        id="filter-types"
        multiple
        limitTags={2}
        disabled={disabled}
        options={optionsFilter}
        value={value}
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        size="small"
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.displayName ?? option.name ?? ''}
        onChange={(_, type) => {
          setValue(type)
          onTypeChange(type.map((type) => type.id) ?? [])
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Types')}
          />
        )}
      />
    </FormControl>
  )
}
