import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import Map from '../../Map/components/Map';
import MapMarkersEvents from '~/features/Map/components/MapMarkers/MapMarkersEvents';
import MapHandlePopUp from '~/features/Map/components/MapHandlers';
import MapLocationFavorite from '~/features/Map/components/MapLocationFavorite';
import useSystemState from '~/features/System/stores/SystemState';
import AreaPlans from '~/features/Map/components/AreaPlans';
import useUserState from '~/features/User/stores/UserState';
import { State, useHookstate } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';
import useDispatchState from '../stores/DispatchState';
import { getDispatchProcedures } from '../services';
import useSurveillanceReportingState from '~/features/VideoMonitoring/stores/SurveillanceReportingState';
import { listSurveillanceReportingByDispatchId } from '~/features/VideoMonitoring/services';

export default function Index() {
  const { windowDefault, windowIsNotVisible, windowReadOnly, loadDraggables, windowManager } = useSystemState();
  const { dispatch, dispatchStatusActions } = useDispatchState();
  const { editingDispatch } = dispatchStatusActions()
  const { id, type, subtype } = dispatch();
  const { verifyPermission } = useUserState();
  const { translate } = useLang();

  useEffect(() => {
    !editingDispatch.get() && type?.get()?.id && getDispatchProcedures({ typeId: type?.get()?.id, subtypeId: subtype?.get()?.id })
      .then((response) => {
        response.length > 0 && !windowManager()['dispatchProcedure'].alreadyOpened.get() && windowManager()['dispatchProcedure'].open.set(true)
        windowManager()['dispatchProcedure'].procedure.set(response)
      })

    return () => {
      windowManager()['dispatchProcedure'].open.set(false)
      windowManager()['dispatchProcedure'].procedure.set([])
    }
  }, [id.get(), type.get(), subtype.get()])

  useEffect(() => {
    windowManager()['dispatchProcedure'].alreadyOpened.set(false)

    if(id.get()){
      listSurveillanceReportingByDispatchId({
        dispatchId: id.get(),
        page: 0,
      }).then((response) => {
        response?.data.length && windowManager()['videoMonitoringCards'].open.set(true)
      })
    }

    return () => {
      windowManager()['videoMonitoringCards'].open.set(false)
    }
  }, [id.get()])

  useEffect(() => {
    document.title = translate('Dispatch - Dispatch System')
  }, [])

  const viewGroups = useHookstate<string[]>(['device', 'dispatch'])
  const isNotVisible = useHookstate([
    'occurrence',
    'occurrenceList',
    'videoMonitoring',
    'VehicleModal',
    'entryRecall',
    'EntryLogModal',
    'DispatchUnification',
    'DispatchDisunification',
    'registration',
    'videoMonitoringCards',
    'MessageModal',
    'entryProcedure',
    'dispatchProcedure',
    'dispatchesResultSearches',
    'mapFilters',
    'mapHistoricFilters',
  ]);

  React.useEffect(() => {
    loadDraggables([
      'OccurrenceModalDraggable',
      'OccurrenceListModalDraggable',
      'VideoMonitoringModalDraggable',
      'VehicleModalDraggable',
      'EntryRecallModalDraggable',
      'EntryLogModalDraggable',
      'MessageModalDraggable',
      'EntryFormModalDraggable'
    ])
    windowDefault(['dispatch']);
    verifyPermission(['camera_index']) ? viewGroups.merge(['camera']) : false;
    if (verifyPermission(['entry_index'])) {
      viewGroups.merge(['entry'])
    } else {
      isNotVisible.merge(['entry'])
    }

    windowIsNotVisible(isNotVisible.get());
    verifyPermission(['dispatch_read_only', 'entry_read_only']) ? windowReadOnly(['dispatch', 'entry', 'searchVehicle', 'searchInvolvement']) : false;
  }, []);


  return (
    <Layout mapViewPort marginLayout={false} draggableComponents={true}>
      <Map>
        <AreaPlans />
        <MapLocationFavorite />
        <MapHandlePopUp readonly={verifyPermission(['dispatch_read_only', 'entry_read_only'])} />
        <MapMarkersEvents viewGroups={viewGroups.get()} checkedViewGroups={['entry', 'device', 'dispatch']} />
      </Map>
    </Layout>
  );
}
