import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAssociatedPeopleFromOccurrenceService } from '~/features/Occurrence/services/index';
import { dateFormatNoTimeZone } from '~/utils/dateTime';

export interface peopleHistoryProps {
  readonly id: number,
  readonly name: string,
  readonly cpf: number,
  readonly age: number,
  readonly nickname: string,
  readonly mother_name: string,
  readonly father_name: string,
  readonly birth_date: string,
  readonly phone_number: number,
  readonly address: string,
  readonly address_number: number,
  readonly cep: string,
  readonly address_reference: string,
  readonly district: string,
  readonly city_id: number,
  readonly state_id: number,
  readonly arrested: number,
  readonly notes: string,
  readonly situation: string,
  readonly final_destination: string,
  readonly is_lgbt: boolean,
  readonly is_public_agent: boolean,
  readonly other_document_type: string,
  readonly involvement_type: {
      readonly id: number,
      readonly name: string
  },
  readonly gender: {
    readonly id: number,
    readonly name: string
  },
  readonly ethnicity: {
    readonly id: number,
    readonly name: string
  },
  readonly education: {
    readonly id: number,
    readonly name: string
  },
  readonly people_circunstance: {
    readonly id: number,
    readonly name: string
  },
  readonly destination: {
    readonly id: number,
    readonly name: string
  },
  readonly organization: {
    readonly id: number,
    readonly name: string
  },
  readonly state: {
    readonly id: number,
    readonly name: string
  },
  readonly city: {
    readonly id: number,
    readonly name: string
  },
  readonly other_document: {
    readonly id: number,
    readonly name: string,
  }
}

interface InvolvedInTheOccurrencePrintProps {
  readonly InvolvedInTheOccurrencePrintResponse: any
}

export default function InvolvedInTheOccurrencePrint({ InvolvedInTheOccurrencePrintResponse }: InvolvedInTheOccurrencePrintProps){
  const { translate } = useLang();
 

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        { InvolvedInTheOccurrencePrintResponse.length != 0 ? (
          InvolvedInTheOccurrencePrintResponse.map((people) => (
            <Grid container key={people?.id} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
              <Grid item xs={3} sx={{border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                <Typography variant='subtitle1'><b>{people?.involvement_type?.name}</b><br/><Typography variant='subtitle1'>{people?.people_circunstance?.name}</Typography></Typography>
              </Grid>
              <Grid container xs={9} padding={2}>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Name')}:</b> {people?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('CPF')}:</b> {people?.cpf ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Birthdate')}:</b> {people?.birth_date ? dateFormatNoTimeZone(people?.birth_date)  : `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Age')}:</b> {people?.age > 0 ? people?.age : `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Gender')}:</b> {people?.gender?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Mother')}:</b> {people?.mother_name ?? `* ${translate('Uninformed')} *`} </Typography>  
                  <Typography variant='subtitle2'><b>{translate('Father')}:</b> {people?.father_name ?? `* ${translate('Uninformed')} *`} </Typography>  
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Nickname')}:</b> {people?.nickname ?? `* ${translate('Uninformed')} *`} </Typography>                  
                  <Typography variant='subtitle2'><b>{translate('Ethnicity')}:</b> {people?.ethnicity?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Type')}:</b> {people?.involvement_type?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Final Destination')}:</b> {people?.destination?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  {people?.other_document?.name && people?.other_document_type ? <Typography variant='subtitle2'><b>{people?.other_document?.name ?? ''  }:</b> {people?.other_document_type ?? `* ${translate('Uninformed')} *`} </Typography> : false  }
                  
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Address')}:</b> {`${people?.address ? people?.address + ' ' : ''}${people?.address_number ? people?.address_number + ' ' : ''}${people?.cep ? people?.cep + ' ' : ''}${people?.address_reference ? people?.address_reference + ' ' : ''}${people?.city ? people?.city?.name + ' ' : ''}${people?.state ? people?.state?.name + ' ' : ''}`.length > 0 ? 
                  `${people?.address ? people?.address + ' ' : ''}${people?.address_number ? people?.address_number + ' ' : ''}${people?.cep ? people?.cep + ' ' : ''}${people?.address_reference ? people?.address_reference + ' ' : ''}${people?.city ?  ' - ' + people?.city?.name : ''}${people?.state ? ' - ' + people?.state?.name : ''}` 
                  : `* ${translate('Uninformed')} *`} 
                  </Typography>
                  <Typography variant='subtitle2'><b>{translate('Notes')}:</b> {people?.notes ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
        </Grid>
        )}
      </Grid>
    </Container>
  )
}