import { authFetch } from '~/services/fetch';
import { LatLng } from 'leaflet';
interface IRequestStatistic {
  readonly agencyId: number | null;
  readonly deviceId: number | null;
  readonly typeId: string | number | null;
  readonly subtypeId: string | number | null;
  readonly startDate: Date | null;
  readonly endDate: Date | null;
  readonly exludeClosed: boolean;
  readonly cityId: number | null;
}

export async function getEntryDashboardTodayService() {
  try {
    const response = await authFetch({
      url: '/entry/dashboard/today',
      method: 'GET',
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getEntryTimeByCenter({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-time-by-center',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryTimeByStation({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-time-by-station',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryTimeByUser({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-time-by-user',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getOpenEntryByCenter({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/open-entry-by-center',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryByCenter({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-by-center',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getOpenEntryByStation({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/open-entry-by-station',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryByStation({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-by-station',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getOpenEntryByUser({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/open-entry-by-user',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryByUser({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-by-user',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryByTypeSubtype({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-by-type-subtype',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryByType({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-by-type',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getEntryByStatus({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed,
  cityId
}: IRequestStatistic) {
  try {
    const response = await authFetch({
      url: '/statistics/entry-by-status',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        typeId: typeId,
        subtypeId: subtypeId,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: exludeClosed,
        cityId: cityId
      }
    });
    return response.data;
  } catch (error) {
    throw error
  }
};

export async function getMarkersEntryService(){
  try {
    const { data } = await authFetch({
      url: '/entry/get-my-entries',
      method: 'POST'
    });

    const result = {};

    data.filter((entry) => entry.latitude && entry.longitude)
      .map((entry, index) => {
        if(entry.latitude && entry.longitude){
          result[entry.id] = {
            id: entry.id,
            markerId: `entry-${entry.id}`,
            code: entry.id,
            location: entry?.location,
            position: new LatLng(entry.latitude, entry.longitude),
            typeName: entry?.typename,
            subtypeName: entry?.subtypename,
            isCurrent: false,
            dispatchedAt: entry.dispatched_at,
          }
        }
        return false
      })
      .filter((item) => item)

      return result
  } catch (error){
    throw error
  }
}

export async function getManagentStationsEntryService(){
  try {
    const { data } = await authFetch({
      url: '/entry/contact-center/overview',
      method: 'GET'
    });

    return data    

  } catch (error){
    throw error
  }
}

export async function getEntryProcedures({typeId, subtypeId=null}) {
  try {
    const response = await authFetch({

      url: '/procedure/config/get-procedure',
      method: 'POST',
      data: {
        type_id: typeId,
        subtype_id: subtypeId,
        related: 0
    }
  })
    
    return response.data;

  } catch (error) {

    throw error
  }
}

export async function getManagentStationsTagService(){
  try {
    const { data } = await authFetch({
      url: '/contact-center/tags',
      method: 'GET'
    });

    return data    

  } catch (error){
    throw error
  }
}

export async function saveStationsPositions(contactCenterId, stations){
  try {
    const { data } = await authFetch({
      url: '/station/management',
      method: 'POST',
      data: {
        contact_center_id: contactCenterId,
        stations: stations
    }
    });

    return data    

  } catch (error){
    throw error
  }
}

export async function saveStationsManagementTags(contactCenterId, tags){
  try {
    const { data } = await authFetch({
      url: '/contact-center/tag/management',
      method: 'POST',
      data: {
        contact_center_id: contactCenterId,
        tags: tags
    }
    });

    return data    

  } catch (error){
    throw error
  }
}

export async function deleteTag(tagId){
  try {
    const { data } = await authFetch({
      url: `/contact-center/tag/${tagId}`,
      method: 'DELETE',
    });

    return data    

  } catch (error){
    throw error
  }
}