import React, { useEffect } from 'react'
import { useLang } from '~/hooks/useLang'
import ErrorFallback from '~/components/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'
import Layout from '~/components/Layout'
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Tab,
} from '@mui/material'
import { DispatchSearchBox } from '~/components/DispatchSearchBox'
import { useHookstate, useState } from '@hookstate/core'
import {
  downloadReportCSV,
  makeRequestToGetOccurrenceConsultation,
} from '../../services/report'
import notify from '~/utils/notify'
import useReportState from '../../stores/ReportState'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import GenerateReportButton from '../../components/GenerateReportButton'
import ExportButtonCSV from '../../components/ButtonExportCSV'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import AreasTreeView, {
  RenderTree,
} from '~/features/DispatchAreaConfig/components/AreasTreeView'
import PhoneTextField from '~/components/PhoneField'
import EntryOriginSelect from '~/features/Entry/components/EntryOriginSelect'
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'

type ListOccurrenceConsultationTab = 'simple' | 'detailed'

interface childrenMapProps {
  readonly id: number;
  readonly parent: number;
}

// eslint-disable-next-line max-lines-per-function
export default function OccurrenceConsultation() {
  const { reportData, loadingReport } = useReportState()
  const { translate } = useLang()
  const expandFilterSearch = useHookstate<boolean>(true)
  const isLoading = useState(false)
  const isSearchDialog = useState(false)
  const tab = useHookstate<ListOccurrenceConsultationTab>('simple')
  const reportName = 'occurrence-consultation'
  const IdDispatch = useHookstate<number | null>(null)
  const groupId = useHookstate<readonly number[]>([])

  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const agencyIds = useHookstate<readonly number[]>([])
  const labelButton = useHookstate('')
  const period = useHookstate<string>('')
  const lockFields = useHookstate<boolean>(false)
  const phone = useHookstate<string | null>(null)
  const entryOriginId = useHookstate('')
  const entryOriginStation = useHookstate('')
  const cityId = useHookstate<number | null>(null)
  const groupSetId = useHookstate<number | null>(null)
  const typeIds = useHookstate<number[]>([])
  const subtypesIds = useHookstate<number[]>([])
  const clearFilter = useHookstate<boolean>(false)

  const typesAndSubtypes = useHookstate<RenderTree>({
    id: 'root',
    name: translate('ALL'),
    description: '',
    selected: false,
    parent: '',
    children: [],
    randomId: ''
  })

  const typeOurSubtypeRequest = useHookstate<RenderTree>({
    id: 'root',
    name: translate('ALL'),
    description: '',
    selected: false,
    parent: '',
    children: [],
    randomId: ''
  })

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  const handleTabDispatch = (
    event: React.SyntheticEvent,
    value: ListOccurrenceConsultationTab
  ) => {
    tab.set(value)
  }

  useEffect(() => {
    IdDispatch.set(null)
  }, [])

  const checkSeleted = (childrenRoot) => {
    let result: any = [];
    childrenRoot?.map((type) => {

      const subtype = type.children
      if (subtype?.length) {
        if (type.selected == true) {
          result = result.concat({ id: parseInt(type.id), parent: undefined })
        }
        subtype.filter(ch => ch.selected == true).map(cha => {
          result = result.concat({ id: parseInt(cha.id), parent: parseInt(cha.parent) })
        })
      } else {
        if (type.selected == true) {
          result = result.concat({ id: parseInt(type.id), parent: undefined })
        }
      }
    })

    return result
  }

  function generateReport() {
    typeIds.set([])
    subtypesIds.set([])

    if (IdDispatch.get() || (startDate && finishDate)) {
      loadingReport().set(true)
      isLoading.set(true)
      const childrenRoot = typeOurSubtypeRequest.get().children;
      const childrenMap: readonly childrenMapProps[] = checkSeleted(childrenRoot)
      childrenMap.map((element) => {

        if (element.parent) {
          if (!subtypesIds.get().includes(element.id)) {
            subtypesIds.set(prevState => [...prevState, ...[element.id]])
            if (!typeIds.get().includes(element.parent)) {
              typeIds.set(prevState => [...prevState, ...[element.parent]])
            }
          }
        } else {
          if (!typeIds.get().includes(element.id)) {
            typeIds.set(prevState => [...prevState, ...[element.id]])
          }
        }
      })

      makeRequestToGetOccurrenceConsultation({
        dispatchId: tab.get() === 'simple' ? IdDispatch.get() : null,
        agencyIds: tab.get() === 'detailed' ? agencyIds.get() : [],
        dispatchGroupsId: tab.get() === 'detailed' ? groupId.get() : [],
        finishDate: tab.get() === 'detailed' ? finishDate.get() : null,
        startDate: tab.get() === 'detailed' ? startDate.get() : null,
        phone: tab.get() === 'detailed' ? phone.get() : null,
        entryOriginId: tab.get() === 'detailed' ? entryOriginId.get() : null,
        cityId: tab.get() === 'detailed' ? cityId.get() : null,
        groupSetId: tab.get() === 'detailed' ? groupSetId.get() : null,
        typeIds: tab.get() === 'detailed' ? typeIds.get() : [],
        subtypeIds: tab.get() === 'detailed' ? subtypesIds.get() : [],
      })
        .then((response) => {
          reportData().set(response)
          window.open(`/report/result/${reportName}`)
        })
        .finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({
        message: translate('Dispatch code cannot be empty'),
        type: 'error',
      })
    }
  }

  const exportCSV = async () => {
    typeIds.set([])
    subtypesIds.set([])

    if (IdDispatch.get() || tab.get() === 'detailed') {
      isLoading.set(true)
      const childrenRoot = typeOurSubtypeRequest.get().children;
      const childrenMap: readonly childrenMapProps[] = checkSeleted(childrenRoot)
      childrenMap.map((element) => {

        if (element.parent) {
          if (!subtypesIds.get().includes(element.id)) {
            subtypesIds.set(prevState => [...prevState, ...[element.id]])
            if (!typeIds.get().includes(element.parent)) {
              typeIds.set(prevState => [...prevState, ...[element.parent]])
            }
          }
        } else {
          if (!typeIds.get().includes(element.id)) {
            typeIds.set(prevState => [...prevState, ...[element.id]])
          }
        }
      })

      const dataCSV = await makeRequestToGetOccurrenceConsultation({
        dispatchId: tab.get() === 'simple' ? IdDispatch.get() : null,
        agencyIds: tab.get() === 'detailed' ? agencyIds.get() : [],
        dispatchGroupsId: tab.get() === 'detailed' ? groupId.get() : [],
        finishDate: tab.get() === 'detailed' ? finishDate.get() : null,
        startDate: tab.get() === 'detailed' ? startDate.get() : null,
        phone: tab.get() === 'detailed' ? phone.get() : null,
        entryOriginId: tab.get() === 'detailed' ? entryOriginId.get() : null,
        cityId: tab.get() === 'detailed' ? cityId.get() : null,
        groupSetId: tab.get() === 'detailed' ? groupSetId.get() : null,
        typeIds: tab.get() === 'detailed' ? typeIds.get() : [],
        subtypeIds: tab.get() === 'detailed' ? subtypesIds.get() : [],
        exportCSV: true,
      }).finally(() => isLoading.set(false))
      downloadReportCSV(
        `${translate(reportName)}-${new Date().getTime()}.csv`,
        dataCSV
      )
    } else {
      notify({
        message: translate('Dispatch code cannot be empty'),
        type: 'error',
      })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  'Occurrence Consultation'
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid container direction={'column'} rowGap={2} padding={2}>
              <TabContext value={tab.get()}>
                <TabList onChange={handleTabDispatch}>
                  <Tab
                    label={translate('Simple filter')}
                    iconPosition="end"
                    value="simple"
                  />
                  <Tab
                    label={translate('Detailed filter')}
                    iconPosition="end"
                    value="detailed"
                  />
                </TabList>
                <TabPanel sx={{ padding: 0 }} value="simple">
                  <Grid item xs={4} sx={{ pl: 1, pt: 1 }}>
                    <DispatchSearchBox
                      isOpen={isSearchDialog}
                      isValid={true}
                      notOnlyVisibles={true}
                      isReport={true}
                      dispatchId={IdDispatch}
                    />
                  </Grid>
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="detailed">
                  <Grid container direction={'row'} rowGap={2} xs={12}>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteAgencies
                        disabled={lockFields.get()}
                        agenciesId={agencyIds.get()}
                        onAgenciesChange={(agency) => {
                          labelButton.set(translate('Search'))
                          agencyIds.set(agency)
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteDispatchGroupSet
                        disabled={lockFields.get()}
                        dispatchGroupSetId={groupSetId?.get() ?? undefined}
                        onGroupDispatchSetChange={(group) => {
                          groupSetId?.set(group)
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteDispatchsGroups
                        disabled={lockFields.get()}
                        agencyId={agencyIds.get() ?? undefined}
                        dispatcheGroups={groupId.get() ?? undefined}
                        onGroupDispatchChange={(group) => {
                          groupId.set(group)
                          labelButton.set(translate('Search'))
                        }}
                        groupSetId={groupSetId.get()}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <PhoneTextField
                        disabled={lockFields.get()}
                        statePhone={phone}
                        disabledLastCalls
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <EntryOriginSelect
                        isValid={true}
                        isInvalidRequest={false}
                        originId={entryOriginId}
                        originStation={entryOriginStation.get()}
                        hasValueDefault={false}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteCity
                        disabled={lockFields.get()}
                        onCityChange={(city) => {
                          cityId?.set(city)
                          labelButton.set(translate('Search'))
                        }}
                        cityId={cityId?.get()}
                      />
                    </Grid>
                    <Grid container direction={'row'}>
                      <FilterSelectPeriod
                        disabled={lockFields.get()}
                        onStartDateChange={(value) =>
                          startDate.set(value?.toJSON() ?? null)
                        }
                        onFinishDateChange={(value) =>
                          finishDate.set(value?.toJSON() ?? null)
                        }
                        onPeriodChange={(value) => {
                          period.set(value)
                        }}
                        period={period.get()}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 1 }}>
                      <AreasTreeView
                        typesAndSubtypes={typesAndSubtypes}
                        typeOurSubtypeRequest={typeOurSubtypeRequest}
                        clearFilter={clearFilter.get()}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Grid>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    IdDispatch.set(null)
                    agencyIds.set([])
                    groupSetId.set(null)
                    groupId.set([])
                    phone.set(null)
                    entryOriginId.set('')
                    cityId.set(null)
                    period.set('')
                    clearFilter.set(!clearFilter.get())
                    typeIds.set([])
                    subtypesIds.set([])
                  }}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={exportCSV}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
