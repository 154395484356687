import React from 'react';
import { State, useHookstate } from '@hookstate/core';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { Close } from "@mui/icons-material";
import { useLang } from '~/hooks/useLang';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { relative } from 'path';

interface CodesGeneratedDialogProps {
  readonly dispatchesCodesGenerated: State<string[]>;
  readonly open: State<boolean>;

}


export default function CodesGeneratedDialog({ dispatchesCodesGenerated, open }: CodesGeneratedDialogProps) {
  const { translate } = useLang();

  const handleCloseDialogCodes = () => {
    open.set(false)
  }

  const clickOutModaCodeslClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      open.set(false);
    }
  };

  const copyCodeDispatch = (code) => {
    navigator.clipboard.writeText(code).then(function () {
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  return (
    <Dialog
      open={open.get()}
      onClose={clickOutModaCodeslClose}
      disableEscapeKeyDown={true}
      maxWidth='sm'
    >
      <DialogTitle >
        {translate('Codes generated')}
      </DialogTitle>
      <DialogContent>
        {dispatchesCodesGenerated.map((dispatch) => (
          <Box
            key={dispatch.get()}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={2}
          >
            <Typography >
              {dispatch.get()}
            </Typography>
            <Tooltip title={translate('Copy code')}>
              <IconButton onClick={() => copyCodeDispatch(dispatch.get())}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained'
          size='small'
          onClick={() => handleCloseDialogCodes()}>
          {translate('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}